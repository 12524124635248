import styled from "styled-components"

export const Section = styled.section`
  padding: 5rem 0;
  @media(max-width: ${props => props.theme.breakpoints.desktop})
  {
    padding: 2rem 0;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.contentWidth};
  position: relative;
  z-index: 0;
  @media(max-width: ${props => props.theme.breakpoints.desktop})
   {
    padding: 0 1rem;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Col = styled.div`
  flex: 0 0 ${props => (props.width ? props.width : "100")}%;
  max-width: ${props => (props.width ? props.width : "100")}%;
  text-align: ${props => props.align};
  padding: ${props => props.padding};

  @media(max-width: ${props => props.theme.breakpoints.desktop})
  {
    flex: 0 0 ${props => (props.md ? props.md : props.width)}%;
    max-width: ${props => (props.md ? props.md : props.width)}%;
    margin-bottom: 2rem;
  }

  @media(max-width: ${props => props.theme.breakpoints.tablet})
  {
    flex: 0 0 ${props => (props.sm ? props.sm : props.width)}%;
    max-width: ${props => (props.sm ? props.sm : props.width)}%;
    margin-bottom: 2rem;
  }

  @media(max-width: ${props => props.theme.breakpoints.phone})
  {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 2rem;
  }
`

export const Title = styled.h2`
  font-size: 34px;
  color: ${props => (props.reversed ? '#ffffff' : props.theme.textColor)};
  position: relative;
  text-align: ${props => (props.center ? 'center' : 'left')};
  font-weight: 500;
  margin-top:0;
  &:before {
    content: "";
    width: 75px;
    height: 2px;
    background: ${props => (props.reversed ? '#ffffff' : props.theme.primaryColor)};
    position: absolute;
    bottom: -1rem;
    left: ${props => (props.center ? '50%' : '0')};
    transform: ${props => (props.center ? 'translateX(-50%)' : 'none')};
  }
  @media(max-width: ${props => props.theme.breakpoints.phone})
   {
    font-size: 24px;
  }
`

export const Subtitle = styled.p`
  max-width: 600px;
  color: ${props => (props.reversed ? '#ffffff' : props.theme.textColor)};
  text-align: ${props => (props.center ? 'center' : 'left')};
  font-size: 16px;
  line-height: 1.7rem;
  margin: ${props => (props.center ? 'auto auto 4rem auto' : '0')};
  @media(max-width: ${props => props.theme.breakpoints.phone})
   {
    font-size: 14px;
    margin: ${props => (props.alignLeft ? 'auto auto 1rem auto' : 'auto auto 2rem auto')};
  }
`

export const IconSection = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${props => (props.reverse ? "#ffffff" : "#f4f9ff")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
`
export const Text = styled.p`
  color: ${props => (props.reversed ? "#999999" : props.theme.textColor)};
  padding: ${props => props.padding};
  @media(max-width: ${props => props.theme.breakpoints.phone})
   {
    font-size: 14px;
  }
`

export const Headline4 = styled.h4`
  font-size: 18px;
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
  font-weight: 600;
  @media(max-width: ${props => props.theme.breakpoints.phone})
   {
    margin: 1rem 0;
  }
`

export const CloseButton = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content:flex-end;
  right:2rem;
  top:2rem;
  svg {
    transition: all linear 0.2s;
    max-width: 35px;
    &:hover {
        transform: scale(1.3);
        transition: all linear 0.2s;
    }
  }
  
`
