import React from "react"
import {Link} from 'gatsby-plugin-modal-routing'
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { theme, GlobalStyle } from "./style/theme"
import CookieConsent from "react-cookie-consent"
import SEO from '../components/common/SEO'

const Root = styled.div`
  background: transparent;
  overflow: hidden;
`

const CustomLink = styled(Link)`
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
`


const Layout = ( {children} ) => {

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <SEO />
        <GlobalStyle />
        <Root>{children}</Root>
        <CookieConsent
          location="bottom"
          buttonText="Prihvaćam"
          enableDeclineButton
          declineButtonText="Odbijam"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#fff", fontSize: "13px", background: "#c6a15a" }}
          expires={150}
        >
          Deša.hr koristi kolačiće kako bi se osiguralo bolje korisničko
          iskustvo i funkcionalnost stranica. Više informacija o kolačićima
          možete potražiti <CustomLink to="/privacy" asModal>ovdje</CustomLink>, a za nastavak pregleda i korištenja
          stranica, kliknite na "Prihvaćam".
        </CookieConsent>
      </React.Fragment>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
