import { createGlobalStyle } from "styled-components"

export const theme = {
  contentWidth: "1200px",
  textColor: "#6b6b6b",
  primaryColor: "#c6a15a",
  secondaryColor: "#c2c2c2",
  reversedColor: "#ffffff",
  section: {
    background: "#f4f9ff",
    margin: "3rem",
    borderRadius: "10px",
  },
  breakpoints: {
    largeDesktop: "1600px",
    desktop: "1200px",
    largeTablet: "1024px",
    tablet: "768px",
    phone: "576px",
  },
}

export const GlobalStyle = createGlobalStyle`

      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: local('Quicksand Regular'), local('Quicksand-Regular'), url(https://fonts.gstatic.com/s/quicksand/v9/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: local('Quicksand Regular'), local('Quicksand-Regular'), url(https://fonts.gstatic.com/s/quicksand/v9/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 400;
        src: local('Quicksand Regular'), local('Quicksand-Regular'), url(https://fonts.gstatic.com/s/quicksand/v9/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

        html {  
            height: 100%;
            overflow: hidden;
            background: url('http://url.com/image.jpg') no-repeat top center fixed;
            background-size: cover; 
        }
    
        body {  
            height:100%;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
        }
    
    }

      html {
        height: 100vh; /* set viewport constraint */
        min-height: 100%; /* enforce height */
    }

    body {
        font-family: 'Quicksand', sans-serif;
        margin:0;
        padding:0;
    }
    p {        
        font-size:16px;
        line-height:1.5rem;
    }
    .ReactModal__Body--open {
      overflow: hidden;
    }

    .ReactModal__Overlay {
      transition: all 0.3s linear;
      transform: translateY(-100%);
    }

    .ReactModal__Overlay--after-open{
        transition: all 0.3s linear;        
        transform: translateY(0);
    }

    .ReactModal__Overlay--before-close{
        transition: all 0.3s linear;        
        transform: translateY(-100%);
    }

    .back-to-top {
      z-index:13;
    }

    img {
      max-width:100%;
    }
`
